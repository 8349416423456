<template>
  <div class="section">
    <navigation></navigation>
    <div class="sec-adoptatree">
        <div class="adoptatree-desc mt-10">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta aut atque dolorum ut minima illum nihil aspernatur explicabo culpa, quasi voluptas, eum tempore nulla neque repellendus ea necessitatibus doloribus dolor? Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>
    </div>

    <div v-if="heritageTree.length == 0">Loading..</div>
    <!-- <div v-else>{{heritageTree}}</div> -->

    <!-- <v-row class="mt-10">
        <v-col cols="12" md="6" class="text-center">
            
            <v-btn
                id="all-trees"
                class="ma-2"
                color="success"
                :to="{name: 'LaFincaTrees'}"
                >
                OUR AVAILABLE TREES
            </v-btn>
            
        </v-col>
        <v-col cols="12" md="6" class="text-center">
            <v-btn
                id="my-trees"
                class="ma-2"
                color="success"
                :to="{name: 'MyTrees'}"
                >
                VISIT MY TREE
            </v-btn>
        </v-col>
    </v-row> -->

    <v-row class="pa-5 mt-8" v-else>
      <v-col cols="12" md='3' v-for="(item, i) in heritageTree" :key="i">
        <div class="property_1">
          <router-link :to="'heritage-trees/' + String(item.id)" style="text-decoration: none;">
            <v-card>
              <v-img
                alt="Alteza Map"
                contain
                :src="'https://sandbox-lafinca-web.wbagadion.com/img/'+ item.img"
                transition="scale-transition"
                
              />
            <v-card-title class="text-center">{{item.name}}</v-card-title>
            </v-card>
          </router-link>
        </div>
      </v-col>

      
    </v-row>

  </div>
</template>

<script>
import axios from 'axios'
import Navigation from './Navigation.vue'
export default {
    components: { Navigation, },
    data(){
      return{
        heritageTree: []
      }
    },
    created(){
      this.getHeritageTree();
    },
    methods:{
      getHeritageTree(){
        axios.get('https://lafincaapi.wbagadion.com/api/get/heritage_tree')
        .then(res => {
          console.log(res)
          this.heritageTree = res.data
        })
        .catch(err => {
          console.error(err); 
        })
      }
    }

}
</script>

<style>
    .section{padding: 5px 65px 5px 65px;}
    .sec-adoptatree{
        margin-top: 20px;
        text-align: justify;
    }
    .adoptatree-desc{
        padding: 5px 50px;
    }
    
    @media only screen and (max-width: 600px) {
      .section{
        padding: 0px;
      }
    }


</style>